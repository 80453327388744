require('./modal')
import { flashMessage } from '../shared/notify';
import { Client, Account, ID } from 'appwrite';

const appWriteClient = new Client();

console.log(process.env)
appWriteClient
  .setEndpoint(process.env.apiEndpoint)
  .setProject(process.env.projectId)
;

window.logoutUser = function() {
  $.ajax({
    method: 'DELETE',
    url: '/appwrite/signout_user'
  }).done((data) => {
    new Account(appWriteClient).deleteSession('current').then((response) => {
      console.log(response)
    }, (error) => {
      console.log(error)
      localStorage.removeItem('jwt');
    })
    //flashMessage("L'utilisateur a été déconnecté", '', 'success')
  })
}

window.openSuccess = function() {
  $.ajax({url: '/checkout/success', dataType: 'script'})
}

function deleteSession(callback) {
  let account = new Account(appWriteClient);
  account.getSession('current').then(function (response) {
    if(response.$id) {
      account.deleteSession(response.$id).then(() => {
        console.log('deleted')
        callback()
      })
    }
  }, function (error) {
      console.log(error); // Failure
    callback()
  });
}

window.createSession = function() {
  let account = new Account(appWriteClient);
  account.createEmailSession('jordan.lamour@toporder.fr', 'password').then(function (response) {
      console.log(response);
  }, function (error) {
      console.log(error);
  });
}

window.generateJWT = function() {
  let account = new Account(appWriteClient);

  const promise = account.createJWT();

  promise.then(function (response) {
    console.log(response);
    signInUser(response.jwt)
  }, function (error) {
    console.log(error);
  });
}

window.signInUser = function(jwt) {
  localStorage.setItem('jwt', jwt)
  $.ajax({
    method: 'POST',
    url: '/appwrite/signin_user',
    data: {jwt: jwt, skip_init: true}
  }).done((data) => {
  })
}

window.logoutUser = function() {
  $.ajax({
    method: 'DELETE',
    url: '/appwrite/signout_user'
  }).done((data) => {
    //flashMessage("L'utilisateur a été déconnecté", '', 'success')
  })
}
// showShop('PickUpLocation', "08dab10a-c408-40e4-8e94-6abcc6bba78d")
window.showCart = function() {
  initModal(true, true)
  $('.modal-content').html(`
    <div class='cart_content background_cart' data-controller='cartcontent'></div>
  `)
  $.get('/cart/reload')
}

window.showShop = function(type, shopId) {
  if($('#map').length == 1) {
    $.get(`/shops/${shopId}/get_slug?type=${type}`, (data) => {
      if(data.slug) {
        Turbolinks.visit(`/shops/${data.slug}`)
      }
    })
  }
}
