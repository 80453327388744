//if (navigator.serviceWorker) {navigator.serviceWorker.register('/service-worker.js', { scope: './' }).then(function(reg) {});}
import { flashMessage } from './notify';
import { Client, Account } from 'appwrite';

$(document).on('turbolinks:load', function() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  $('body').fadeIn(1000)
})

window.onresize = function(event) {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};


$(document).on('click', '.order_block', (e) => {
  if(!$(e.currentTarget).data().legacy) {
   Turbolinks.visit(`/orders/${$(e.currentTarget).get(0).id.split('_').reverse()[0]}`)
  }
})

$(document).on('click', '.modal-default-myaccount__footer__signout', (e) => {
  e.preventDefault()
  const appWriteClient = new Client();
  appWriteClient
      .setEndpoint(process.env.apiEndpoint) // Your API Endpoint
      .setProject(process.env.projectId) // Your project ID
  ;
  localStorage.removeItem('jwt');
  $.ajax({
    url: '/appwrite/signout_user',
    type: 'DELETE',
    success: function(result) {
      new Account(appWriteClient).deleteSession('current').then((response) => {
        flashMessage('', 'Vous avez été déconnecté !', 'success')
        Turbolinks.visit('/account/sign_in')
      }, (error) => {
        console.log(error)
      })

    }
  });
})