import { Controller } from 'stimulus';
import { flashMessage } from '../shared/notify';
export default class FavsController extends Controller {
  openShop(e) {
    console.log($(e.target))
    if($(e.target).parents('.favorite_container').length) {
      return false
    }
    let type = $(e.currentTarget).find('.favorite_container').data().shoppable.split('_')[0] == 'LocalShop' ? 'Shop' : 'PickUpLocation'
    let uuid = $(e.currentTarget).find('.favorite_container').data().uuid
    $.get(`/shops/${uuid}/get_slug?type=${type}`, (data) => {
      if(data.slug) {
        Turbolinks.visit(`/shops/${data.slug}`)  
      }
    })
  }
}
