import { Controller } from 'stimulus';
import { Client, Account } from 'appwrite';
export default class PasswordchangeController extends Controller {
  // /password/balancing?userId=4bd55005-8f8e-47b5-afd5-47f69bf94e4a&secret=c6d10f4c68cc029120b2ae299787b2eaecc69e62677c3de388485680d7bd8e35d5a93956a06e1f61a9e92f8f128fa79ea7f741a3fdc985dca088e2f7ad074735b217a0f41f000a0b0c68b840ea7c83e1f75cbb5a37419059297f4cf501b0452c91c876023c409d65cfc48eb8bf95e1c4b85eebe52a065dade7db745d235ccdb4&expire=2023-01-04+12%3A40%3A42.091
  connect() {
    this.password = ''
    this.password_confirm = ''
    this.userId = $(this.element).data().userId
    this.secret = $(this.element).data().secret
    this.appWriteClient = new Client();
    this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    this.account = new Account(this.appWriteClient);

    $('.passwordchange_form').on('submit', (e) => {
      e.preventDefault()
      this.check_password_token($('#user_password_token'))
      if(this.password_valid && this.password_confirm_valid && this.password_token_valid) {
        let form = $('.passwordchange_form')
        $.ajax({
          type: 'POST',
          url: form.attr('action'),
          data: form.serialize(),
          success: function(data) {
            if(data.error) {
              $('.session_error').text(data.error)
              $('.session_error').removeClass('d-none')
            } else {
              $('#account .simple_form').html(`
                <p class="text-center password_change_confirm">Votre mot de passe a été modifié. Vous pouvez l'utiliser pour vous connecter</p>
              `)
            }
          }
        })
      }

      setTimeout(() => {
        $('.passwordchange_form .submit_button').prop('disabled', false)
      }, 100)
    })
  }

  submit() {
    $(this.element).find('> .text-danger').addClass('d-none')
    this.check_password($('.user_password input'))
    this.check_password_confirm($('.user_password_confirmation input'))
    if(!this.password_valid || !this.password_confirm_valid) { return }

    this.account.updateRecovery(this.userId, this.secret, this.password, this.password).then((response) => {
      console.log(response);
      this.redirect()
    }, (error) => {
      $(this.element).find('> .text-danger').removeClass('d-none')
      console.log(error.code);
    });
  }

  redirect() {
    $('#account').html(`
      <p>Votre mot de passe a été correctement modifié.</p>
      <p>Vous allez être redirigé dans quelques instants. Vous pourrez ensuite vous connecter avec votre nouveau mot de passe.</p>
      <div class='position-relative' style='width: 100%; height: 60px;'>
        <span class="loading_container">
          <span class="loading_circle"></span>
        </span>
      </div>
    `)
    setTimeout(() => {
      Turbolinks.visit('/')
    }, 2500)
  }

  check_password(input) {
    this.password = input.val()
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.password_valid = false
      input.next().text("Veuillez renseigner un mot de passe")
      return false;
    }
    if(input.val().length < 8) {
      input.addClass('is-invalid')
      this.password_valid = false
      input.next().text("Votre mot de passe n'est pas assez long (minimum 8 charactères)")
      return false;
    }
    input.addClass('is-valid')
    this.password_valid = true
  }

  password_change(e) {
    let input = $(e.currentTarget)
    input.removeClass('is-valid is-invalid')
    this.check_password(input)
  }

  check_password_confirm(input) {
    this.password_confirm = input.val()
    if(input.val().length == 0) {
      input.addClass('is-invalid')
      this.password_confirm_valid = false
      input.next().text("Veuillez confirmer votre mot de passe")
      return false;
    }
    if(input.val() != this.password) {
      input.addClass('is-invalid')
      this.password_confirm_valid = false
      input.next().text("Les deux mots de passe sont différents")
      return false;
    }
    input.addClass('is-valid')
    this.password_confirm_valid = true
  }

  password_confirm_change(e) {
    let input = $(e.currentTarget)
    input.removeClass('is-valid is-invalid')
    this.check_password_confirm(input)
  }
}
