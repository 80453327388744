import { Controller } from 'stimulus';
var moment = require('moment');
export default class CartController extends Controller {

  connect() {
    this.refresh()
  }

  toggle() {
    $('.loading_app_container').removeClass('d-none')
    initModal(true, true)
    $('.modal-content').html(`
      <div class='cart_content background_cart' data-controller='cartcontent'></div>
    `)
    $.get('/cart/reload')

  }

  refresh() {
    $.get('/cart/load')
  }
}
