import { Controller } from 'stimulus';
export default class MyaccountController extends Controller {

  connect() {
    console.log('bouhhh')
    $('.modal-default-myaccount__loyalty-container__card').height(
      $('.modal-default-myaccount__loyalty-container__card').width() * 0.59
    )
  }
}
