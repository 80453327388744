import { Controller } from "stimulus"
import { MainController } from './main_controller';

export default class BasketmenufooterController extends MainController {
  connect() {
    this.element['controller'] = this
  }

  submit() {
    console.log('bouh')
    $('#form_slider').get(0).controller.submit()
  }
}