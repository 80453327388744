import { Controller } from 'stimulus';
import { StripeCoords } from '../classes/stripe_coords';
import { numberToEuro } from '../shared/number_to_euro';
import { department_list } from '../shared/department_list';
import { jwtChecker } from '../shared/jwt_parser';
import PerfectScrollbar from 'perfect-scrollbar';
export default class ShipmentController extends Controller {

  connect() {
    $('.loading_app_container').addClass('d-none')
    this.stripe_coords = new StripeCoords()
    $('.shipment_list_display').html(gon_vars.shipments.map(s => s.department_list).flat().map(e => `<li>${e} ${department_list[e]}</li>`).join(''))
    $(document).on('change', '.contact-information-form input', () => {
      this.toggle_shipment()
    })
    this.toggle_shipment()
  }

  previous() {
    $.get('/cart/reload')
  }

  toggle_shipment() {
    if($('.contact-information-form input').map((index, element) => $(element).length > 0).get().includes(false)) {
      $('.shipment_coords_incomplete').show()
      $('.shipment_result').hide()
      $('.modal-cart-container__submit-button').addClass('modal-cart-container__submit-button-disabled')
      $('.modal-cart-container__submit-button').prop('disabled', true)
    } else {
      $('.modal-cart-container__submit-button').removeClass('modal-cart-container__submit-button-disabled')
      $('.modal-cart-container__submit-button').prop('disabled', false)
      $('.shipment_coords_incomplete').hide()
      $('.shipment_result').show()
      let shipment = gon_vars.shipments.filter(shipment => shipment.department_list.includes($('#user_postal_code').val().substring(0, 2)))[0]
      if(typeof shipment !== 'undefined') {
        $('.cant_choose_shipment').removeClass('d-flex')
        $('.can_choose_shipment').show()
        $('.shipment_price_display').text(numberToEuro(shipment.price))
        this.shipment_id = shipment.id
      } else {
        $('.cant_choose_shipment').addClass('d-flex')
        $('.can_choose_shipment').hide()
        $('#user_shipment').prop('checked', false)
        this.shipment_id = undefined
      }
    }
  }

  async submit() {
    jwtChecker().then((data) => {
      if(($('#user_shipment').prop('checked') && this.stripe_coords.valid_coords()) || !$('#user_shipment').prop('checked')) {
        $('.loading_app_container').removeClass('d-none')
        $.ajax({
          url: '/shipment',
          type: 'POST',
          data: {
            address: $('#user_address').val(),
            postal_code: $('#user_postal_code').val(),
            city: $('#user_city').val(),
            phone: $('#user_phone').val(),
            shipment: $('#user_shipment').prop('checked'),
            shipment_id: this.shipment_id
          },
          success: () => {
            $.ajax({url: '/cart/recap', dataType: 'script'})
          }
        })
      }
    })    
  }
}
