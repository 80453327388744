import { Controller } from 'stimulus';
import { StripeCoords } from '../classes/stripe_coords';
import { StripeCards } from '../classes/stripe_cards';
import { v4 as uuidv4 } from 'uuid';
import { Client, Account } from 'appwrite';

export default class StripeController extends Controller {

  connect() {
    $('.loading_app_container').addClass('d-none')
    this.stripe_coords = new StripeCoords()
    this.amount = gon_vars.amount
    this.stripe = Stripe(gon_vars.stripe_public, {locale: 'fr'});
    this.appWriteClient = new Client();
    this.appWriteClient.setEndpoint(process.env.apiEndpoint).setProject(process.env.projectId);
    this.account = new Account(this.appWriteClient);
    this.try_direct_pay()
  }

  prev() {
    $('.loading_app_container').removeClass('d-none')
    $.ajax({url: '/cart/recap', dataType: 'script'})
  }

  reset_payment_method() {
    $('.choose_payment_method_container').removeClass('d-none')
    $('.payment_submit_container').addClass('d-none')
    $('.checkout_payment_visa').addClass('d-none')
    $('.submit_payment').attr('disabled', false)
    $('.checkout_payment_ban_contact').addClass('d-none')
    this.stripe = undefined
    this.stripe_cards = undefined
    this.payment_method = undefined
  }

  choose_payment_method(e) {
    $('.choose_payment_method_container').addClass('d-none')
    if($(e.currentTarget).data().paymentmethod == 'visa') {
      $.get(`/checkout/payment_intent_id?method=card`, (data) => {
        this.payment_intent_secret = data.payment_intent_secret
        this.stripe = Stripe(data.stripe_public, {locale: 'fr'});
        this.stripe_cards = new StripeCards(this.stripe, this.payment_intent_secret)
        this.amount = data.amount
        this.stripe_cards.init_cards()
        this.payment_method = 'card'
      })
    } else if ($(e.currentTarget).data().paymentmethod == 'bancontact') {
      $.get(`/checkout/payment_intent_id?method=bancontact`, (data) => {
        this.payment_intent_secret = data.payment_intent_secret
        this.payment_method = 'bancontact'
        $('.checkout_payment_ban_contact').removeClass('d-none')
        $('.payment_submit_container').removeClass('d-none')
        this.stripe = Stripe(data.stripe_public, {locale: 'fr'});
      })
    }

  }

  submit() {
    if(!this.stripe_coords.valid_coords()) {return false}
    // De base payment card est à false, donc tente le direct pay
    // Sinon, valide le paiement par carte
    $('.submit_payment').addClass('loading_item')
    $('.submit_payment').attr('disabled', true)
    $('.loading_app_container').removeClass('d-none')
    $('body').addClass('loading')
    this.account.createJWT().then((response) => {
      localStorage.setItem('jwt', response.jwt)
      $.ajax({
        method: 'POST',
        url: '/appwrite/signin_user',
        data: {jwt: response.jwt}
      }).done(() => {
        this.submit_payment()
      })
    }, function (error) {
      console.log(error);
    });

  }

  submit_payment() {
    if(this.payment_method == 'card') {
      this.create_order().then(data => {
        if(data.success) { 
          this.stripe_cards.submit_payment() 
        }
      })
    } else if (this.payment_method == 'bancontact') {
      this.create_order().then(data => {
        if(data.success) {
          this.stripe.confirmBancontactPayment(
            this.payment_intent_secret,
            {
              payment_method: {
                billing_details: { name: $('#user_name').val() }
              },
              return_url: `https://${window.location.hostname == 'localhost' ? 'backend.toporder.fr' : window.location.hostname}/checkout/redirect_bancontact`
            }
          ).then(function(result) {
            if (result.error) { console.log(result) }
          })
        }
      })
    }
  }

  try_direct_pay() {
    this.paymentRequest = this.stripe.paymentRequest({
      country: 'FR',
      currency: 'eur',
      total: {label: 'TOTAL', amount: this.amount},
    })
    var prButton = this.stripe.elements().create('paymentRequestButton', {paymentRequest: this.paymentRequest});
    this.paymentRequest.canMakePayment().then((result) => {
      if (result) {
        prButton.mount('#payment-request-button')
        this.paymentRequest.on('paymentmethod', (ev) => {
          $('.submit_payment').addClass('loading_item')
          $('.submit_payment').attr('disabled', true)
          $.get(`/checkout/payment_intent_id?method=card`, (data) => {
            this.payment_intent_secret = data.payment_intent_secret
            this.result = ev
            if(!this.stripe_coords.valid_coords()) {return false}
            this.account.createJWT().then((response) => {
              localStorage.setItem('jwt', response.jwt)
              $.ajax({
                method: 'POST',
                url: '/appwrite/signin_user',
                data: {jwt: response.jwt}
              }).done(() => {
                this.create_order().then(data => {
                  if(data.success) { this.handle_direct_result() }
                })
              })
            }, function (error) {
              console.log(error);
            });
          })
        })
      }
      //this.place_icons()
    }).catch((error) => {
      console.log(error)
    })
  }

  handle_direct_result() {
    this.timeout_stripe = true
    setTimeout(() => {
      if(this.timeout_stripe) {
        $('#modal-checkout > *').hide()
        $('#modal-checkout .modal-title').show()
        $('.modal-back').css('visibility', 'hidden')
        $('#error_stripe_no_response').removeClass('d-none')
        $('#error_stripe_no_response').show()
        $('.loading_app_container').addClass('d-none')
      }
    }, 10000)
    this.stripe.confirmCardPayment(
      this.payment_intent_secret,
      {payment_method: this.result.paymentMethod.id},
      {handleActions: false}
    ).then((confirmResult) => {
      this.timeout_stripe = false
      if (confirmResult.error) {
        this.payment_error(confirmResult.error)
      } else if (confirmResult.paymentIntent.status == 'requires_capture'){
        this.valid_order()
      } else if (confirmResult.paymentIntent.status === "requires_action") {
        this.stripe.confirmCardPayment(this.payment_intent_secret).then((result) => {
          result.error ? this.payment_error(result.error.message) : this.valid_order()
        })
      }
    });
  }

  payment_error(error_message) {
    $('body').removeClass('loading')
    this.display_card_error(error_message)
    this.result.complete('fail');
  }

  valid_order() {
    $('body').addClass('loading')
    this.result.complete('success');
    $.get('/checkout/valid_order', (data) => {window.location = data.location})
  }

  display_card_error(error) {
    $('body').removeClass('loading')
    $('.error_card_submit').removeClass('d-none')
    $('.error_card_submit').text(error)
  }

  create_order(callback) {
    return new Promise((resolve, reject) => {
       $.ajax({
        url: '/checkout/create_order',
        type: 'POST',
        data: {
          name: $('#user_name').val(),
          address: $('#user_address').val(),
          postal_code: $('#user_postal_code').val(),
          city: $('#user_city').val(),
          phone: $('#user_phone').val(),
          uuid: uuidv4(),
          comment: $('#checkout_comment').val()
        },
        success: (data) => {
          console.log(data)
          resolve(data)
        }
      })

    })
  }
}
